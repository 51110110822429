.loudspeaker {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.loudspeakerModal > .modal-content.modal-info > .modal-header {
  padding: 0px 20px 0px;
}

.loudspeakerModal > .modal-content.modal-info > .modal-body {
  padding: 0px 20px 20px 20px;
}

.loudspeakerTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.loudspeakerTitle > .robot.robot-sm {
  height: 110px;
  width: 70px;
}

.loudspeakerTitle > .robot > .robot-bg {
  transform: translate(0px,-20px) scale(.50)
}  

.loudspeakerTitle > .robot.robot-sm > .robot-figure {
  transform: scale(.50) translate(-45%) ;
}  

.loudspeakerTitle__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  font-size: 29px;
  color: #7a8797;
  text-align: center;
  font-weight: 500;
  margin: 0 15px;
}

.loudspeakerAnnouncement {
  display: flex;
  box-shadow: 2px 2px 2px 2px rgba(235, 235, 235, 1);
  margin-bottom: 15px;
  flex-direction: column;
}

.loudspeakerAnnouncement__icon {
  margin-top: -3px;
}

.loudspeakerAnnouncement__title {
  display: flex;
  flex-direction: column;
  padding: 2px 20px 0px 20px;
  margin-bottom: 4px;
}

.loudspeakerAnnouncement__snippet {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0px 20px 5px 20px;
  font-size: 12px;
}

.loudspeakerAnnouncement__snippet > div > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.loudspeakerAnnouncement__snippet > div > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.loudspeakerAnnouncement__contentOnly {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 10px 20px 11px 20px;
  font-size: 12px;
}

.loudspeakerAnnouncement__contentOnly--withGroupings {
  padding: 10px 20px 0px 20px;
}

.loudspeakerAnnouncement__contentOnly > div > p {
  margin: 0px;
}

.loudspeakerAnnouncement__contentOnly > div > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.loudspeakerAnnouncement__contentOnly > div > :last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.loudspeakerAnnouncement__contentOnly img {
  max-width: 450px;
}

.loudspeakerAnnouncement__groupings {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding: 10px 20px 5px 20px;
}

.loudspeakerAnnouncement__grouping {
  margin-right: 10px;
}

.loudspeakerAnnouncement__groupingIcon {
  padding-right: 3px;
  color: #efa444;
}

.loudspeakerAnnouncement__readMoreLink {
  display: flex;
  width: fit-content;
  cursor: pointer;
  text-decoration: underline;
  padding: 0px 0px 11px 20px;
}

.loudspeakerAnnouncement__readMoreLink--withGroupings {
  padding: 0px 0px 0px 20px;
}

.loudspeakerViewAllButton {
  display: flex;
}

.loudspeakerViewAllButton__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.loudspeakerViewAllButton__link {
  background: none;
  color: inherit;
  border: none;
  margin-right: 15px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-bottom: 1px solid rgb(65, 181, 220);
  color: rgb(65, 181, 220);
}

.loudspeakerViewAllButton__link:hover {
  color: none;
  text-decoration: none;
}

.loudspeakerViewAllButton__link:visited {
  text-decoration: none;
}

.loudspeakerViewAllButton__link:focus {
  text-decoration: none;
}

.loudspeakerNoAnnouncements {
  font-style: italic;
  margin-bottom: 15px;
}

.loudspeakerFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loudspeakerFooter__secondaryButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.loudspeakerFooter__secondaryButton {
  background: none;
  color: inherit;
  border: none;
  margin-right: 15px;
  padding: 0;
  font: inherit;
  cursor: pointer;
  border-bottom: 1px solid rgb(65, 181, 220);
  color: rgb(65, 181, 220);
}

.loudspeakerIcon {
  padding: 5px 10px;
}

.loudspeakerList__sectionTitle {
  padding-top: 5px;
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  text-align: center;
}